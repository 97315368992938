<!-- <mat-toolbar *ngIf="data.mobileType === 'android'" color="primary" class="android-prompt">
    <button mat-button (click)="installPwa()">
      Add to Home screen
    </button>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
   -->
   <div *ngIf="data.mobileType === 'android'" class="ios-prompt">
    <button class="close-ios-inv" mat-button color="primary" (click)="close()"><mat-icon>close</mat-icon></button>
    <div>To install this web app on your device tap the <img height="20px" width="20px" src="../../../assets/icons/three-dots-vertical.svg"> Menu button and then click on 'Install app' or 'Add to Home screen' button</div>
  </div>
  <div *ngIf="data.mobileType === 'ios'" class="ios-prompt">
    <button class="close-ios-inv" mat-button color="primary" (click)="close()"><mat-icon>close</mat-icon></button>
    <div>To install this web app on your device tap the <img height="20px" width="20px" src="../../../assets/icons/ios-menu-btn.png"> Menu button and then click on  'Add to Home screen' button</div>
  </div>